import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gsec from '../components/logicSec/logicbase'
import Mgsec from '../components/logicSec/mobilelogic/mlogic'

// import PageHeader from '../util/pageHeader'
import {kitchendata} from '../components/logicSec/lgicdata'
import imge from '../images/colorswatch/Kitchen/1/k1 (5).jpg'
import {kndata} from '../data/housegdata'


const Kitchen = () => (
  <Layout>
    <SEO title="Kitchen" />

<Gsec data={kitchendata} kdata={kndata} imgekt={imge}/>
<Mgsec data={kitchendata}  kdata={kndata} imgekt={imge}/>
  </Layout>
)

export default Kitchen
